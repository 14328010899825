<template>
  <div class="main">
    <div class="banner"></div>
    <div class="cha">
      <div class="title_b">
        <b>产品概述</b>
        <div class="s">
          支持兑换、满赠、满减等多种类型卡券；支持活动、商城、线下等多种使用场景；<br />
          具备经过验证的高并发、高可用、高性能安全解决方案
        </div>
        <div class="icon"></div>
      </div>
      <div class="content flex_box">
        <div class="img-das"></div>
        <div class="card" v-for="(i, index) in cl" :key="index">
          <div class="img">
            <img :src="i.img" alt="" />
          </div>
          <div class="title">{{ i.t }}</div>
          <div
            class="color"
            v-for="(e, ei) in i.list"
            :key="ei"
            :style="{ background: i.bgcolor, color: i.color }"
          >
            {{ e }}
          </div>
        </div>
      </div>
    </div>
    <div class="fe">
      <div class="title_b">
        <b>特色&优势</b>
        <div class="s">
          面向多渠道
          长期高并发实战经验，已完成多渠道对接，使用消息队列，异步兑换，实时到账，双十一、618等重点节日优惠券秒杀活动中<br />
          通过Redis原子锁精确控制奖品数量，同时保障系统的性能和安全
        </div>
      </div>
      <div class="flex_box content">
        <div class="card" v-for="(i, index) in fl" :key="index">
          <img :src="i.img" alt="" />
          <div class="title">{{ i.t }}</div>
          <div class="color"></div>
          <div>{{ i.s }}</div>
        </div>
      </div>
    </div>
    <div class="title_b">
      <b>产品功能</b>
      <div class="s">
        优惠券中心目前已支撑满赠券、折扣券、兑换券、线下门店券、互联网异业券等全类型优惠券<br />
        已具备制券-发券-领券-核销闭环流程的优惠券体系
      </div>
      <div class="icon"></div>
    </div>
    <div class="fc content">
      <div class="card" v-for="(i, index) in functionList" :key="index">
        <img :src="i.img" class="img" alt="" />
        <div class="heade" :style="{ backgroundImage: `url(${i.bg})` }">
          {{ i.t }}
        </div>
        <div class="card_body">
          <div class="text" v-for="(e, ei) in i.list" :key="ei">{{ e }}</div>
        </div>
      </div>
    </div>
    <div class="sce content">
      <div>应用场景<span class="s">线上 / 线下多场景应用</span></div>
      <div class="flex_box">
        <div class="card" v-for="(i, index) in sl" :key="index">
          <div class="heade">
            {{ i.t }}
            <div class="icon">{{ index + 1 }}</div>
          </div>
          <div v-for="(e, ei) in i.l" :key="ei">{{ e }}</div>
        </div>
      </div>
    </div>
    <div class="title_b">
      <b>服务支持</b>
      <div class="s">
        卡券中心提供了规范、系统、安全的对外接口能力，系统的卡券配置平台，完善的数据体系
      </div>
    </div>
    <div class="content">
      <img src="../../assets/img/productList/cs/coupons_service.jpg" alt="" />
    </div>
    <div class="case">
      <div class="title_b">
        <b>客户案例</b>
        <div class="icon"></div>
      </div>
      <div class="case_body content">
        <div class="title">App领券活动</div>
        <div class="flex_box">
          <div class="card" v-for="(i, index) in cl1" :key="index">
            <img :src="i.img" alt="" />
            <b>{{ i.t }}</b>
            <div>{{ i.s }}</div>
          </div>
        </div>
        <div class="hr"></div>
        <div class="title">线上线下协同领券活动</div>
        <div class="flex_box">
          <div class="card" v-for="(i, index) in cl2" :key="index">
            <img :src="i.img" alt="" />
            <b>{{ i.t }}</b>
          </div>
          <div class="card">
            <ul>
              <li v-for="(i, index) in cl3" :key="index">{{ i }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cl: [
        {
          img: require("../../assets/img/productList/cs/cp_1.png"),
          t: "制券/发券",
          bgcolor: "#ebf4ff",
          color: "#4d7dbe",
          list: ["满赠券", "折扣券", "兑换券", "服务券"],
        },
        {
          img: require("../../assets/img/productList/cs/cp_2.png"),
          t: "领券",
          bgcolor: "#ddf1f7",
          color: "#568390",
          list: ["活动领券", "场景领券", "领券中心", "积分兑换"],
        },
        {
          img: require("../../assets/img/productList/cs/cp_3.png"),
          t: "用券",
          bgcolor: "#eee8f8",
          color: "#756e84",
          list: ["活动领券", "场景领券", "领券中心", "积分兑换"],
        },
      ],
      fl: [
        {
          img: require("../../assets/img/productList/cs/ca_1.png"),
          t: "分布式部署+IP鉴权",
          s: "卡券系统采用分布式集群环境部署，通过DUBBO+ZOOKEEPER实现服务的调用渠道调用需进行IP鉴权",
        },
        {
          img: require("../../assets/img/productList/cs/ca_2.png"),
          t: "短信自动预警",
          s: "对卡券数据进行实时监控，接口调用失败、卡券库存不足、卡券领取异常的情况，能够及时发送短信、邮件进行预警",
        },
        {
          img: require("../../assets/img/productList/cs/ca_3.png"),
          t: "加盐加密",
          s: "领券中心不直接暴露卡券类型编码，通过加盐加密方式保证同一类型卡券，不同用户显示的卡券类型编码不同",
        },
        {
          img: require("../../assets/img/productList/cs/ca_4.png"),
          t: "REDIS缓存",
          s: "通过REDIS的原子锁控制卡券数量，定时同步至数据库，避免高并发下产生脏数据，通过使用REDIS缓存，降低对数据库频繁读写造成的性能压力",
        },
        {
          img: require("../../assets/img/productList/cs/ca_5.png"),
          t: "实战经验丰富",
          s: "面向多渠道 长期高并发实战经验，已完成多渠道对接，使用消息队列，异步兑换，实时到账，具备成熟的秒杀经验",
        },
        {
          img: require("../../assets/img/productList/cs/ca_6.png"),
          t: "种类齐全、场景丰富",
          s: "目前已支撑满赠券、折扣券、兑换券、线下门店券、互联网异业券等全类型优惠券。已成为具备制券-发券-领券-核销闭环流程的优惠券体系",
        },
      ],
      functionList: [
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_1.png"),
          img: require("../../assets/img/productList/cs/coupons_function_1.png"),
          t: "渠道",
          list: ["APP", "小程序", "公众号", "分享"],
        },
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_2.png"),
          img: require("../../assets/img/productList/cs/coupons_function_2.png"),
          t: "卡券配置",
          list: ["满赠券", "异业券", "兑换券", "服务券"],
        },
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_3.png"),
          img: require("../../assets/img/productList/cs/coupons_function_3.png"),
          t: "发放场景",
          list: ["系统发券", "场景发券", "用户领券"],
        },
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_3.png"),
          img: require("../../assets/img/productList/cs/coupons_function_3.png"),
          t: "卡券核销",
          list: ["线上核销", "线下核销"],
        },
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_3.png"),
          img: require("../../assets/img/productList/cs/coupons_function_3.png"),
          t: "角色",
          list: ["管理员", "商户/门店", "省/地市/区县", "普通用户"],
        },
        {
          bg: require("../../assets/img/productList/cs/coupons_function_title_3.png"),
          img: require("../../assets/img/productList/cs/coupons_function_3.png"),
          t: "底层平台",
          list: ["H5卡券功能", "ECP卡券接口", "卡券配置平台"],
        },
      ],
      sl: [
        {
          t: "线上领券",
          l: ["领券中心", "活动领取", "规则触发"],
        },
        {
          t: "线下领券",
          l: ["物料铺放", "H5页面", "二维码投放"],
        },
        {
          t: "线上用券",
          l: ["充值", "购买", "办理", "兑换"],
        },
        {
          t: "线下用券",
          l: ["折扣", "抵扣", "兑换"],
        },
      ],
      cl1: [
        {
          img: require("../../assets/img/productList/cs/coupons_case_1.jpg"),
          t: "领券中心",
          s: "卡券领取入口，各种流量、话费异业资源均可领取",
        },
        {
          img: require("../../assets/img/productList/cs/coupons_case_2.jpg"),
          t: "充值及业务办理",
          s: "满赠券用于充值、业务办理，促进价值的转化",
        },
        {
          img: require("../../assets/img/productList/cs/coupons_case_3.jpg"),
          t: "蜂蜜商城",
          s: "作为任务中心的兑换资源，提升用户粘性",
        },
        {
          img: require("../../assets/img/productList/cs/coupons_case_4.jpg"),
          t: "异业周边",
          s: "与线下异业资源结合，线上为营业厅引流",
        },
      ],
      cl2: [
        {
          img: require("../../assets/img/productList/cs/coupons_case_5.jpg"),
          t: "肯德基活动",
        },
        {
          img: require("../../assets/img/productList/cs/coupons_case_6.jpg"),
          t: "果粒橙活动",
        },
        {
          img: require("../../assets/img/productList/cs/coupons_case_7.jpg"),
          t: "唯品会活动",
        },
      ],
      cl3: [
        "合作方通过单页、二维码、易拉宝等进行宣传推广，展示企业Logo，明确活动参与形式",
        "用户通过入口进入参与活动",
        "未激活用户，下载app，达到拉新的目的",
        "登录后领取，兑换成功后，告知用户，增加用户对产品/app的服务满意度。",
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
$img: "../../assets/img/productList/cs/";
.banner {
  background-image: url($img + "banner.jpg");
}
.flex_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cha {
  background: url($img + "bg1.jpg") center 0 / 2560px auto no-repeat;
  padding-bottom: 90px;
  .flex_box {
    margin-top: 95px;
    position: relative;
    .img-das {
      position: absolute;
      top: -50px;
      width: 1260px;
      height: 29px;
      background: url($img + "cpa.png");
    }
  }
  .card {
    position: relative;
    width: 380px;
    height: 330px;
    padding-top: 80px;
    background: url($img + "cp_bg.png") 0 0 /100% no-repeat;
    box-shadow: 0px 5px 54px 0px rgba(53, 53, 53, 0.09);
    text-align: center;
    .img {
      width: 140px;
      height: 140px;
      position: absolute;
      top: -105px;
      left: calc(50% - 70px);
    }
    .title {
      font-size: 26px;
      color: #000000;
      font-weight: bold;
    }
    .color {
      width: 250px;
      height: 40px;
      line-height: 40px;
      margin: 25px auto 0 auto;
      border-radius: 20px;
    }
  }
}
.fe {
  background: url($img + "cp_f_bg.jpg") #f6f9fc 0 0 /100% no-repeat;
  padding-bottom: 50px;
  .flex_box {
    height: 667px;
    align-content: space-between;
  }
  .card {
    width: 350px;
    text-align: center;
    font-size: 16px;
    color: #616161;
    img {
      width: 120px;
    }
    .title {
      font-size: 26px;
      color: #313131;
      font-weight: bold;
      margin-top: 20px;
    }
    .color {
      width: 30px;
      height: 8px;
      background-color: #1b65fb;
      border-radius: 4px;
      margin: 20px auto;
    }
  }
}
.fc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .card {
    width: 380px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    margin-bottom: 50px;
    position: relative;
    top: 0;
    transition: 0.2s;
    &:hover {
      box-shadow: 0px 3px 20px 0px rgba(53, 153, 248, 0.15);
      top: -10px;
    }
    .img {
      position: absolute;
      width: 80px;
      height: 80px;
      top: -10px;
      right: -10px;
    }
    .heade {
      padding-left: 20px;
      width: 360px;
      height: 60px;
      font-size: 24px;
      font-weight: bold;
      line-height: 72px;
      color: #000000;
      background-color: #e5f1ff;
    }
    .card_body {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 70px;
      padding: 50px 0 40px 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 20px;
      color: #313131;
      .text::before {
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        background-color: #3280fd;
        border-radius: 3px;
        transform: rotate(45deg);
        margin-right: 10px;
        vertical-align: 3px;
      }
    }
  }
}
.sce {
  width: 1160px;
  background: url($img + "cs_bg.png") no-repeat;
  padding: 120px 20px 47px 80px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 50px;
  line-height: 24px;
  color: #ffffff;
  .s {
    font-size: 20px;
    margin-left: 30px;
  }
  .flex_box {
    margin-top: 130px;
    background-color: #ffffff;
    height: 380px;
    box-shadow: 0px 5px 54px 0px rgba(53, 53, 53, 0.09);
    border-radius: 10px;
    display: flex;
    align-content: center;
    .card {
      flex: 1;
      height: 260px;
      border-right: 1px solid #f1f1f1;
      text-align: center;
      font-size: 22px;
      line-height: 50px;
      color: #313131;
      .icon {
        position: absolute;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        font-size: 28px;
        color: #806b36;
        background-color: #e9ddbf;
        line-height: 44px;
        top: -31px;
        left: -17px;
      }
      .heade {
        position: relative;
        display: inline-block;
        width: 160px;
        height: 50px;
        background-image: linear-gradient(
          45deg,
          #1b65fb 0%,
          #256cfd 0%,
          #2e73ff 0%,
          #2196f3 100%
        );
        border-radius: 25px;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 50px;
      }
    }
  }
}
.case {
  background-color: #f5f5f5;
  margin-top: 100px;
  padding-bottom: 74px;
  .case_body {
    width: 1040px;
    padding: 35px 110px;
    background-color: #ffffff;
    border-radius: 20px;
    text-align: center;
    .title {
      display: inline-block;
      padding: 0 25px;
      height: 44px;
      line-height: 44px;
      background-color: #e1efff;
      border-radius: 22px;
      color: #4277b4;
      font-size: 22px;
    }
    .flex_box {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 50px;
      .card {
        width: 200px;
        font-size: 14px;
        color: #717171;
        line-height: 22px;
        b {
          font-size: 16px;
          color: #000000;
          margin-top: 30px;
          display: block;
        }

        li {
          text-align: left;
          font-size: 16px;
          color: #717171;
          margin-top: 25px;
          &::marker {
            color: #2196f3;
          }
        }
      }
    }
    .hr {
      border-top: 1px solid #f7f7ff;
      margin-bottom: 34px;
    }
  }
}
</style>